.text-input {
    padding: 10px 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .text-input:focus {
    border-color: #3f51b5; /* MUI primary color */
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2); /* Focus effect */
  }
  