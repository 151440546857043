.prompt-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #1976D2; // MUI primary blue
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  
    &:hover {
      background-color: darken(#1976D2, 10%); // Darkens the button on hover
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(29, 155, 209, 0.5);
    }
  
    &:active {
      background-color: darken(#1976D2, 20%);
    }
  }
  