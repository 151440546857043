/* LoginRegister.scss */


.login-register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        height: 450px;
    }

    form {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: white;
        width: 300px;

        input {
            width: 100%;
            padding: 10px;
            margin: 10px 0;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-sizing: border-box;

            &:focus {
                outline: none;
                border-color: #09f;
            }
        }

        .error {
            color: #d32f2f;
            margin-bottom: 10px;
            text-align: center;
        }

        button {
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 4px;
            margin-top: 10px;
            cursor: pointer;
            background-color: #09f;
            color: white;
            font-weight: bold;

            &:hover {
                background-color: darken(#09f, 10%);
            }

            &:focus {
                outline: none;
            }

            &:first-of-type {
                margin-bottom: 10px;
            }
        }
    }
}