html,
body {
    background-color: #030404;
}

#apainter-ui {
    font-family: Helvetica, Arial, sans-serif;
    position: absolute;
    left: 20px;
    bottom: 20px;
    line-height: 1.2em;
    background-color: rgba(10, 10, 10, 0.8);
    border-radius: 6px;
    padding: 1.7em 2em;
    display: none;
}

#apainter-ui .help {
    width: 600px;
    color: #ccc;
    font-size: 14px;
    text-shadow: 0px 2px 1px #000;
}

#apainter-ui .help h1 {
    font-size: 22px;
    font-weight: 100;
    color: #e42b5a;
    margin-top: 0;
}

#apainter-ui .button {
    background-color: #ef2d5e;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    width: 130px;
    text-align: center;
    max-width: 115px;
    padding: 6px;
}

#apainter-ui .button:hover {
    background-color: #f43b6a;
}

#apainter-ui .form {
    display: flex;
    align-content: center;
}

#apainter-ui .share input {
    width: 600px;
    color: #333;
    text-align: center;
}

#apainter-ui .hide {
    display: none !important;
}

#apainter-ui .progress {
    font-size: 22px;
    font-weight: 100;
    color: #e42b5a;
    border-bottom: 1px solid #e42b5a;
    text-shadow: 0px 2px 1px #000;
}

#apainter-ui .bar {
    width: 0%;
    height: 3px;
    margin-top: 13px;
    background-color: #e42b5a;
}


#apainter-logo.hidden,
#apainter-author.hidden {
    display: none;
}

#apainter-author {
    position: fixed;
    bottom: 28px;
    left: 268px;
    display: inline-block;
    font: 18px arial, sans-serif;
    color: #e42b5a;
}

[data-canvasbucket] {
    >div {
        /* border: solid 1px #212121; */
        border-radius: 3px;
        outline: solid 4px #FEFFFF;
    }
}