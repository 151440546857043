.menu {
    display: flex;
    gap: 10px; // Adjust the gap between buttons as needed
}

.menuButton {
    background-color: #1976d2; // MUI primary button color
    color: #ffffff;
    border: none;
    padding: 6px 16px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 250ms ease;

    &:hover {
        background-color: #115293; // Darker shade for hover state
    }

    &:focus {
        outline: 2px solid #115293;
        outline-offset: 2px;
    }
}