.select-input-container {
  padding: 10px;
}

.select-label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #e9e9e9;
  /* Label text color */
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-input {
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  cursor: pointer;
  width: 100%;
  /* Make select fill container */
}

.select-input:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}

.select-container::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  pointer-events: none;
}